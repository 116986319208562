"use client";

import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/navigation";
import typesenseClient from "@/lib/typesense/client";

export default function SearchBox() {
  const router = useRouter();
  const searchBoxRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const onKeyDown = e => {
    if (e.key === "Enter") {
      router.push(`/search?q=${encodeURIComponent(searchValue)}`);
    }
  };

  const fetchProductData = async searchValue => {
    const searchRequests = {
      searches: [
        {
          collection: "bikebiz-ecom",
          query_by: "title, variant_sku_list",
          q: searchValue,
          filter_by: "isPublished:true",
          per_page: 100,
          min_len_1typo: 3,
          min_len_2typo: 5,
        },
        {
          collection: "bikebiz-motorcycles",
          query_by: "product_name",
          q: searchValue,
          filter_by: "is_published:true",
          per_page: 100,
          min_len_1typo: 3,
          min_len_2typo: 5,
        },
      ],
    };
    const response = await typesenseClient.multiSearch.perform(searchRequests);
    if (response && response.results && response.results.length > 0) {
      const filteredData = [];
      const ecomData = response.results[0].hits.map(hit => hit.document);
      const vehicleData = response.results[1].hits.map(hit => hit.document);
      if (ecomData && ecomData.length > 0) {
        filteredData.push(
          ...ecomData.map(product => {
            return {
              name: product.title,
              slug: `/products/${product.url_key}`,
              type: "department",
              variant_sku_list: product.variant_sku_list || [],
            };
          })
        );
      }
      if (vehicleData && vehicleData.length > 0) {
        filteredData.push(
          ...vehicleData
            .filter(product => {
              const name = product.product_name.toLowerCase();
              const search = searchValue.toLowerCase();
              return name.startsWith(search);
            })
            .map(product => {
              var urlProduct = "";
              if (product.type === "new_bike")
                if (product.product_range_slugs && product.product_range_slugs.length > 0) {
                  urlProduct = `/new-bikes/${product.manufacturer.toLowerCase()}/${product.product_range_slugs[0]}/${
                    product.prismic_uid
                  }`;
                } else {
                  urlProduct = `/new-bikes/${product.manufacturer.toLowerCase()}/${product.prismic_uid}`;
                }
              if (product.type === "used_bike") urlProduct = `/used-bikes/${product.prismic_uid}`;
              return {
                name: product.product_name,
                slug: urlProduct,
                type: "vehicle",
                variant_sku_list: [],
              };
            })
        );
      }
      filteredData.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      setSearchResults(filteredData);
    }
  };

  const handleInputChange = value => {
    setSearchValue(value);

    if (value !== "") {
      setShowSearchResult(true);
      fetchProductData(value);
    } else {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
        setShowSearchResult(false);
      } else {
        setShowSearchResult(true);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={searchBoxRef} className=" h-full w-full flex justify-between items-center relative">
      <input
        placeholder="What are you looking for?"
        className="w-full bg-transparent outline-none text-[21px] font-rajdhani px-6"
        value={searchValue}
        onChange={e => handleInputChange(e.target.value)}
        onClick={() => setShowSearchResult(true)}
        onKeyDown={e => onKeyDown(e)}
      />
      <button className="h-[72px] w-[172px] mr-2 rounded-sm uppercase bg-gold text-lg font-bold">Search</button>

      {showSearchResult && searchResults.length > 0 && (
        <div className="container h-fit absolute  mt-1 top-[88px] left-0 bg-white shadow-2xl z-30">
          <div className=" max-h-[360px]  overflow-y-auto">
            {searchResults.map((result, index) => {
              const searchBySku = result.variant_sku_list.find(sku => sku.includes(searchValue));
              return (
                <div
                  key={`search-result-${index}`}
                  onClick={() => {
                    router.push(result.slug);
                  }}
                  className="cursor-pointer"
                >
                  <div className="flex items-center gap-3 py-2 px-8 my-1 bg-white hover:bg-grey-100">
                    <div className="text-sm w-[380px] whitespace-nowrap overflow-hidden text-ellipsis">
                      <b>{result.name}</b> {searchBySku && `(${searchBySku})`}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex items-center gap-3 py-2 px-8 my-6 bg-white">
            <div
              onClick={() => {
                router.push(`/search?q=${encodeURIComponent(searchValue)}`);
                setShowSearchResult(false);
              }}
            >
              <div className="text-sm underline cursor-pointer">View all results for ‘{searchValue}’</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
